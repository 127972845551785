const compTypes = {
  IMAGE_COMPONENT: 'StaticImage', // 静态图组件（单图、双图、三图、四图）
  CODE_IMAGE_COMPONENT: 'StaticImage', // code合图组件
  IMAGE_CAROUSEL_COMPONENT: 'SideslipImage', // 滑动多图组件 （轮播图、横滑3、4、5、6）
  SUPER_DEALS: 'SuperDeals',  // 不贵组件
  VERTICAL_ITEMS: 'VerticalItems', // 纵向商品列表
  HORIZONTAL_ITEMS: 'HorizontalItems', // 横滑商品组件
  RANKING_ENTRANCE: 'RankingEntrance', // 排行榜入口
  ONE_THIRD_CONTAINER_COMPONENT: 'OneThirdContainer', // 1/3商品组件
}

export const compMapping = ({ styleType = '', componentKey = '' }) => {
  return compTypes[styleType] || compTypes[componentKey] || ''
}
