/* eslint-disable */
/**
 * 放量站点，分3个等级，如设置等级，则默认走对应这些站点
 * 与apollo json格式保持一致，先屏蔽eslint
 */

// LEVEL1: SHEIN 、ROMWE PC 各切一个中央站点：
export const LEVEL1 = ["hk","mhk","rw","rwm"]

// LEVEL2：SHEIN 切 20% 站点、ROMWE  全量
export const LEVEL2 = LEVEL1.concat(["id","mid","in","pwin","ru","mru","ma","pwma","vn","mvn","roe","mroe","ch","mch","se","mse","rwar","rwmx","rwau","rwca","rwit","rwes","rwde","rwfr","rwuk","rwus","rwmar","rwmmx","rwmau","rwmca","rwmit","rwmes","rwmde","rwmfr","rwmuk","rwmus"])

// LEVEL3: SHEIN 切 40% 站点
export const LEVEL3 = LEVEL2.concat(["asia","masia","my","mmy","th","mth","tw","mtw","ph","mph","pt","mpt","nl","pwnl","pl","mpl"])
