<template>
  <!-- 渲染多个（单个？）不同业务组件 -->
  <div
    v-if="list.length"
    class="cccx-comp-container"
    :class="$attrs.class"
  >
    <template
      v-for="(data, index) in list"
      :key="`${data.id}_${index}`"
    >
      <LazyHydrationWrapper
        v-if="data.__comp_name === 'StaticImage'"
        :when-visible="isVisibleHydratedComp(data)"
        :when-triggered="!isVisibleHydratedComp(data)"
      >
        <component 
          :is="data.__comp_name || 'StaticImage'"
          :context="context"
          :content="content"
          :scene-data="sceneData"
          :prop-data="data"
          :cate-links="cateLinks"
          :brand="brand"
          :index="index"
          :data-component-name="data.props && data.props.name"
          :data-component-style-type="data.styleType"
          :container-width="containerWidth"
          :prop-hooks="hooks && hooks[data.__comp_name.toLocaleUpperCase()]"
          @hydrated="() => onHydrated(data, index)"
        />
      </LazyHydrationWrapper>
      <component
        :is="data.__comp_name"
        v-else
        :context="context"
        :content="content"
        :scene-data="sceneData"
        :prop-data="data"
        :cate-links="cateLinks"
        :brand="brand"
        :index="index"
        :data-component-name="data.props && data.props.name"
        :container-width="containerWidth"
        :prop-hooks="hooks && hooks[data.__comp_name.toLocaleUpperCase()]"
        @hydrated="() => onHydrated(data, index)"
      >
        <template
          v-for="(_, name) in $slots"
          #[name]
        >
          <slot :name="name"></slot>
        </template>
      </component>
    </template>
  </div>
</template>

<script>
import components from '../common/comp-bff'
import { compMapping } from '../common/comp-bff-map'
import { getComponentHeight } from '../common/utils'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { prefetchResources } from '../common/prefetch-resources'
import { prefetchHtml } from '../common/prefetch-html'
import { LazyHydrationWrapper } from '@shein-aidc/shein-lazy-hydration'
daEventCenter.addSubscriber({ modulecode: '2-19' })

export default {
  name: 'CccAppBff',
  components: {
    ...components,
    LazyHydrationWrapper,
  },
  props: {
    /**
     * App Ccc 环境数据（网站的环境变量等）！！！
     * @param {String} lang [必须] 语言
     * @param {String} SiteUID [必需] 站点UID
     * @param {String} GB_cssRight [必需] 站点多语言方向
     * @param {String} PUBLIC_CDN [非必需，尽量提供] 影响图片的CDN，否则会回源
     * @param {String} WEB_CLIENT [必需] 影响图片展位的样式
     * @param {Number} forceRenderNumber [非必需] 一个让组件懒加载强制渲染的字段
     * @param {Number} serverTime [非必需] 服务端直出的时间戳
     * @param {Object} language 多语言，看情况，目前需要手动传入渲染的组件多语言，如果只有图片不需要传入多语言，TODO：未来需要做成根据渲染的组件拉取多语言
     * @param {String} dateLangMap 日期多语言，TODO：将多语言移动到 language 对象中
     * @param {Object} rankInfo TODO: 需要删掉不能放在context里面
     * @param {Number} id TODO: 页面数据的id，不应该放在context，待整改
     * @param {Object} content TODO: 待整改
     *    @param {String} abtParams TODO: 待整改
     */
    context: {
      type: Object,
      default: () => ({}),
    },
    content: {
      type: Array,
      default: () => [],
    },
    /**
     * ccc 的特殊链接
     */
    cateLinks: {
      type: Object,
      default: () => ({}),
    },
    /**
     * 场景数据，比如场景实验，标识符等（可理解为当前列表应用在那个场景对应的一些公共配置  约等于 页面配置）
     * @param {String} pageFrom [必需] 当前列表应用在哪个页面（前端自己定义的字段，目的是来组件中进行区分）
     * @param {String} id [非必需] 当前数据的ccc配置的id，从接口出来
     * @param {String} pageType [非必需 - 埋点需要] CCC 数据带过来的页面类型
     * @param {String} sceneName [非必需 - 埋点需要] 场景场景名称 - 比如，首页的women，专题叫做campaign
     * 
     * --首页场景 S--
     * @param {String} poskey [非必需 - 埋点需要] 场景实验 - 比如，精投，或者首页频道对应的实验
     * --首页场景 E--
     * 
     * --专题场景 S--
     * @param {String} identity [非必需] 专题标识符
     * @param {String} activityId [非必需 - 埋点需要] 页面id - 专题id
     * @param {String} activityNameEn [非必需 - 埋点需要] 页面标识符 - 专题的标识符
     * --专题场景 E--
     */
    sceneData: {
      type: Object,
      default: () => ({}),
    },
    /** 容器宽度用于计算图片裁切 */
    containerWidth: {
      type: Number,
      default: 1275
    },
    /**
     * 组件场景差异钩子
     * key: 组件名称（在comp中对应组件的名称.toLocaleUpperCase()）
     * value: any，可以是任意类型，会在组件中通过props的方式传入给对应的组件
     * // 以下只是例子并不是真实的hooks
     * eg: {
     *  HORIZONTALITEMS: {
     *    getHorizontalList: () => {},
     *  },
     *  VERTICALITEMS: 'SheinText'
     * }
     */
    hooks: {
      type: Object,
      default: () => ({})
    }
  },
  provide() {
    return {
      sceneData: this.sceneData,
      baseImageMounted: () => {
        this.$emit('baseImageMounted')
      },
      GB_cssRight: this.context?.GB_cssRight,
      isSupportCropImage: true
    }
  },
  emits: ['baseImageMounted'],
  computed: {
    forceRenderNumber() {
      return this.context.forceRenderNumber || 4
    },
    list() {
      return this.content.filter((item, index) => {
        if (!compMapping(item)) return false
        item.__comp_name = compMapping(item)
        item.firstScreen = index < this.forceRenderNumber
        return true
      })
    },
    brand() {
      return this.context?.WEB_CLIENT || 'shein'
    },
  },
  methods: {
    getComponentHeight,
    onHydrated(data, index) {
      prefetchHtml({ sceneData: this.sceneData, propData: data, cateLinks: this.cateLinks, index, context: this.context })
      const el = document.querySelector(`.${this.$attrs.class}`)
      if (!el) return
      prefetchResources({ context: data, el })
    },
    isVisibleHydratedComp(data) { // 基于视口的懒水合组件
      const { __comp_name, styleType, props } = data || {}
      const arr = []
      if (styleType === 'ONE_IMAGE_COMPONENT' && props?.metaData?.enableFloatDisplayAfterReceive) arr.push('StaticImage')
      return !arr.includes(__comp_name)
    },
  },
}
</script>

<style lang="less">
// 解决横向列表商品底部信息撑满
.common-horizontal__swiper-style {
  .swiper-slide {
    height: auto;
  }
}
// 解决纵向列表商品底部信息撑满
.common-vertical__product-style {
  .product-item-ccc {
    height: auto;
  }
}
</style>
