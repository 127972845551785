// https://wiki.dotfashion.cn/pages/viewpage.action?pageId=1398005756
import { LEVEL1, LEVEL2, LEVEL3 } from './config'
const { SiteUID, NEW_BFF_VERSION_SWTICH: apolloConfig  } = gbCommonInfo

const levelMapping = {
  'LEVEL1': LEVEL1,
  'LEVEL2': LEVEL2,
  'LEVEL3': LEVEL3,
}

export function isSwitchBffApiVersion(features) {
  if (!apolloConfig) return {}

  const result = {}
  features.forEach(feature => {
    // 检查指定页面版本是否存在
    const allowedSites = apolloConfig[feature]
    if (!allowedSites) {
      result[feature] = false
      return
    }

    // 只有一个值且为level或 OPENALL时
    if (allowedSites.length === 1) {
      const allowedSite = allowedSites[0]

      // OPENALL全部开放站点
      if (allowedSite === 'OPENALL') {
        result[feature] = true
        return
      }

      // 开放各个level站点
      if (levelMapping[allowedSite]) {
        result[feature] = levelMapping[allowedSite].includes(SiteUID)
        return
      }
    }

    result[feature] = allowedSites.includes(SiteUID)
  })
  return result
}
