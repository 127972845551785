import { initialize } from '@shein-aidc/bs-sdk-libs-manager'
import schttp from '@/public/src/services/schttp'
import UserInfoManager from '@/public/src/services/UserInfoManager/UserInfoManager.js'

export const businessLibInitializeForCSR = (app) => {
  initialize({
    schttp,
    envs: {
      project: 'pc',
      bffEnv: gbCommonInfo.bffEnv,
      webVersion: gbCommonInfo.WEB_VERSION,
      host: gbCommonInfo.host,
      lang: gbCommonInfo.lang,
      forceHttp: gbCommonInfo.forceHttp,
      langPath: gbCommonInfo.langPath,
      siteUID: gbCommonInfo.SiteUID,
      SERVER_TYPE: gbCommonInfo.SERVER_TYPE,
      appLanguage: gbCommonInfo.appLanguage,
      currency: gbCommonInfo.currency,
      csrf_token: gbCommonInfo.csrf_token,
      cssRight: gbCommonInfo.GB_cssRight,
      mir: gbCommonInfo.GB_cssRight ? 'rtl' : 'ltr',
      RESOURCE_SDK: gbCommonInfo.RESOURCE_SDK,
      dateLangMap: gbCommonInfo.dateLangMap,
    },
    getters: {
      isLogin() {
        return UserInfoManager.isLogin()
      },
      memberId() {
        return UserInfoManager.get({ key: 'memberId', actionType: 'bsLibs' }) || ''
      },
      userInfo() {
        return UserInfoManager.get({ key: 'UserInfo', actionType: 'handle/UserDropdown' }) || {}
      },
      userEmail() {
        return UserInfoManager.get({ key: 'userinfo_email', actionType: 'handle/UserDropdown' }) || ''
      },
      SaPageInfo() {
        return window.SaPageInfo || {}
      }
    },
  }, app)
}
