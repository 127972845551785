import { createSSRApp } from 'vue'
import { registerBaseContext } from '../common/common_inject'
import App from './App.vue'

export function _createApp(context) {
  const app = createSSRApp(App, {
    context,
  })
  registerBaseContext(app)
  return { app }
}
