export default {
  refreshAnalysisParams({ params }) {
    if(typeof window != 'undefined') {
      const saInfo = Object.assign({}, window.SaPageInfo, {
        page_param: params
      })
      sa('set', 'setPageData', saInfo)
      sa('send', 'pageEnter', { start_time: new Date().getTime() })
      //退出页面
      window.onbeforeunload = function() {
        sa('send', 'pageLeave', { end_time: new Date().getTime() })
      }

      window.TPM?.publish('viewhome')
    }
  }
}
